<template>
  <mdb-container class="center main">
    <div class="bk"></div>
    <div class="sub">
      <div class="text-center pdt-92 ">
        <img src="@/assets/images/saup_title.png" alt="">
      </div>
      <div class="text-center mgt-11">
        <img src="@/assets/images/saup_sub_title.png" alt="">
      </div>

      <div class="contents">
        <span class="btn_white">
          <img src="@/assets/images/saup_txt_1.png" alt="">
        </span>
        <p class="sub-txt">
          국가 치안 역량 강화 및 대국민 치안 서비스 향상을 위하여 현장 중심의 치안 문제 해결을 선도하는 과학치안 연구개발성과 창출 및 생태계 조성
        </p>
        <span class="btn_white">
          <img src="@/assets/images/saup_txt_2.png" alt="">
        </span>
        <p class="sub-txt">
          - 치안 현장 문제 대응을 위하여 기존에 개발된 원천기술을 적극 활용하고, 현장 경찰관과 긴밀히 소통하는 과학치안 기반의 폴리스랩 확대 추진<br>
          - 현장성과 예방성, 시제품 및 서비스 연구개발 규모, 주제 성격 등에 따라 현장대응형/선제대응형/참여중심형 폴리스랩으로 구분하여 추진<br>
          - 현장(경찰)과의 공감대 형성 및 활용가능성 극대화를 위해 경쟁형 선기획 연구를 거쳐 본연구를 추진함으로써 연구자와 사용자(경찰)간<br>
          &nbsp;&nbsp;&nbsp;협업 촉진 및 현장에서의 실증 연구 고도화
        </p>
        <mdb-row class="image-group">
          <mdb-col>
            <div class="item"><img src="@/assets/images/saup_img_1.png" alt=""></div>
          </mdb-col>
          <mdb-col>
            <div class="item"><img src="@/assets/images/saup_img_2.png" alt=""></div>
          </mdb-col>
        </mdb-row>
      </div>
    </div>

  </mdb-container>
</template>

<script>

import {mdbCol, mdbContainer, mdbRow} from "mdbvue";

export default {
  name: "BusinessView2",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  }
}
</script>

<style scoped>
.main {
  position: relative;

}

.bk {
  background-image: url("../../../assets/images/bk_saup.png");
  position: absolute;
  background-repeat: no-repeat;
  background-position: top center;
  opacity: 0.2;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  width: 100%;
  z-index: 0;
}

.pdt-92 {
  padding-top: 92px;
}

.contents {
  margin-top: 42px;
  padding-bottom: 64px;
  padding-left: 10px;
  padding-right: 10px;
}

.sub {
  position: relative;
  z-index: 1;
  max-width: 976px;
  margin: 0 auto;
}

.btn_white {
  margin: 30px auto 20px;
  width: 126px;
  height: 52px;
  padding: 8px 10px 8px 8px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 1px 4.5px 0.6px rgba(0, 0, 0, 0.4);
  background-color: #f4f4f4;
}

.sub-txt {
  margin: 20px auto 30px;
  max-width: 933px;
  width: 100%;
  font-family: AppleSDGothicNeo, serif;
  font-size: 17px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #151515;
  display: block;
}

.image-group {
  text-align: center;
}

.item img {
  width: 100%;
}

.item:first-child {
  margin-bottom: 20px;
}

</style>
